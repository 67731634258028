import { getUniqueToken } from "../../utils/utils";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setUserInfo } from "../../redux/userSlice";
import { useLocation, useNavigate } from "react-router-dom";

const UserRefreshLogin = () => {
  const { user } = useSelector((store) => store.user);
  let isLoggedin = user;
  const dispatch = useDispatch();

  const LukyX3UserDetails = localStorage.getItem("LukyX3UserDetails");
  if (LukyX3UserDetails) {
    if (!isLoggedin) {
      isLoggedin = true;
      dispatch(setUserInfo(JSON.parse(LukyX3UserDetails)));
    }
  }

  // No login no token moving to login page
  const { pathname } = useLocation();
  const navigate = useNavigate();
  useEffect(() => {
    if (
      !isLoggedin &&
      !getUniqueToken() &&
      !pathname?.includes("signin") &&
      !pathname?.includes("register")
    ) {
      navigate("/signin");
    }
  }, []);

  return isLoggedin;
};

export default UserRefreshLogin;
