import React, { useEffect, useRef } from "react";
import ScratchCard from "react-scratchcard-v2";
import IMG from "./scratch_card.png";
import "./ScratchCardComp.scss";
import Loader from "../Loader/Loader";
import CustomImage from "../CustomImage/CustomImage";
import { useState } from "react";

const ScratchCardComp = ({ onSuccessScratch, winningPrize }) => {
  const [loading, setLoading] = useState(false);
  const ref = useRef(null);

  const onClickReset = () => {
    ref.current && ref.current.reset();
  };

  let width = window.innerWidth - 40;
  if (width > 440) width = 440;

  return (
    <div>
      <ScratchCard
        ref={ref}
        width={width}
        height={width}
        image={IMG}
        finishPercent={20}
        onComplete={() => {
          onSuccessScratch();
          setLoading(true);
          setTimeout(() => {
            onClickReset();
            setLoading(false);
          }, 4000);
        }}
      >
        <div className="scratchPrize">
          {winningPrize ? (
            <>
              <CustomImage
                className="prize_image"
                src={winningPrize?.image}
                alt=""
              />
              <p className="title">You Won</p>
              <p className="title">INR {winningPrize?.amount || 0}</p>
              <p className="title">
                {winningPrize?.name || winningPrize?.title || ""}
              </p>
            </>
          ) : loading ? (
            <p className="title">Loading...</p>
          ) : (
            <p className="title">Scratch More</p>
          )}
        </div>
      </ScratchCard>
    </div>
  );
};

export default ScratchCardComp;
