import React from "react";

const OutlineBg = (props) => {
  return (
    <svg
      height="50"
		  viewBox="0 0 330 50"
		  preserveAspectRatio="none"
		  xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.3681 6.5335C13.8191 2.60713 17.5621 0 21.748 0H308.252C312.438 0 316.181 2.60713 317.632 6.5335L330 40H0L12.3681 6.5335Z"
        fill="#292039"
      />
      <path
        d="M0 40H330L328.012 44.9711C326.797 48.0084 323.855 50 320.584 50H9.94424C6.91406 50 4.14396 48.288 2.78882 45.5777L0 40Z"
        fill="#332B42"
      />
    </svg>
  );
};

export default OutlineBg;
