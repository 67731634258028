import { configureStore } from '@reduxjs/toolkit'
import userReducer from './userSlice'
import homePageReducer from './homePageSlice'
import winnerHistoryReducer from './winnerHistorySlice'


export const store = configureStore({
    reducer: {
        user: userReducer,
        luckydraw: homePageReducer,
        winnerHistory: winnerHistoryReducer
    },
})