import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import {
  clearFieldErrors,
  setUserInfo,
  signupUser,
} from "../../redux/userSlice";
import { toast } from "react-toastify";
import "./RegisterPage.css";
import { removeInvaildCharFromMobileField } from "../../utils/utils";
import { useEffect } from "react";
import CustomInput from "../../Components/CustomInput/CustomInput";
import Button from "../../Components/Button/Button";
import GuestConfirmationPopup from "./Popups/GuestConfirmationPopup";
import GoBackButton from "../../Components/GoBackButton/GoBackButton";
import Logo from "../../assets/svgs/Logo";
import axios from "axios";
import Loader from "../../Components/Loader/Loader";

const RegisterPage = () => {
  const [values, setValues] = useState({});
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [showGuestPopup, setShowGuestPopup] = useState(false);
  const [captchaCode, setCaptchaCode] = useState("");

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const formSubmitHandler = (e) => {
    e.preventDefault();
    let payload = { ...values };
    if (payload.password !== payload.password_confirmation) {
      setErrors({
        ...errors,
        password_confirmation: ["Password and Confirm password not matched"],
      });
      return;
    }

    setLoading(true);
    axios
      .post("/user-register", payload)
      .then((res) => {
        toast.success("Registered successfully pls Login");
        navigate("/login");
        setLoading(false);
      })
      .catch((e) => {
        setLoading(false);

        let error = e?.response?.data?.error;
        if (error?.includes("Registration CAPTCHA code Invalid or expired")) {
          toast.error(error);
          setTimeout(() => {
            fetchCaptcha();
          }, 500);
        } else if (e?.response?.data?.errors) {
          setErrors(e?.response?.data?.errors);
          toast.error(e?.response?.data?.message || "Field Errors");
        } else {
          toast.error("Something went wrong try again ...");
        }
      });
  };

  const inputChangeHandler = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    if (name == "mobile") {
      value = removeInvaildCharFromMobileField(value);
      if (value?.length > 13) return;
    }
    if (errors[name]) {
      setErrors({ ...errors, [name]: null });
    }
    if (errors?.password_confirmation && name === "password_confirmation") {
      if (values.password == value) {
        setErrors({
          ...errors,
          password_confirmation: null,
        });
      } else {
        setErrors({
          ...errors,
          password_confirmation: ["Password and Confirm password not matched"],
        });
      }
    }
    setValues({
      ...values,
      [e.target.name]: value,
    });
  };

  const showGuestPopupHandler = () => {
    setShowGuestPopup(true);
  };
  const hideGuestPopupHandler = () => {
    setShowGuestPopup(false);
  };

  const fetchCaptcha = () => {
    axios
      .get(`/generate-verification-code?context=registration`)
      .then((res) => {
        setCaptchaCode(res?.data?.captchaImageUrl || "");
      });
  };

  useEffect(() => {
    fetchCaptcha();
  }, []);

  return (
    <div className="login-page page ">
      {loading && <Loader />}
      <GoBackButton />
      {showGuestPopup && (
        <GuestConfirmationPopup closer={hideGuestPopupHandler} />
      )}
      <div className="bottom-cont ">
        <div className="logo_cont">
          <Logo />
        </div>

        <p className="form-heading">Create Account</p>
        <p className="text_light">Register to continue!</p>
        <form className="login_form" onSubmit={formSubmitHandler}>
          <div className="flex flex-column ">
            <CustomInput
              className={` ${errors?.username ? " error" : ""}`}
              name="username"
              label="Username"
              placeholder="Username"
              type="text"
              setValues={setValues}
              values={values}
              onChange={inputChangeHandler}
              required
            />
            {errors?.username?.map((each) => (
              <p className="error_msg">{each}</p>
            ))}
          </div>
          <div className="flex flex-column ">
            <CustomInput
              className={` ${errors?.email ? " error" : ""}`}
              name="email"
              label="Email"
              placeholder="Username or Email"
              type="email"
              setValues={setValues}
              values={values}
              onChange={inputChangeHandler}
              required
            />
            {errors?.email?.map((each) => (
              <p className="error_msg">{each}</p>
            ))}
          </div>
          <div className="flex flex-column ">
            <CustomInput
              className={` ${errors?.telegram ? " error" : ""}`}
              name="telegram"
              label="Telegram"
              placeholder="Telegram"
              type="text"
              setValues={setValues}
              values={values}
              onChange={inputChangeHandler}
              required
              hint_msg="You will be contacted with details above"
            />
            {errors?.telegram?.map((each) => (
              <p className="error_msg">{each}</p>
            ))}
          </div>

          <CustomInput
            className={` ${errors?.password ? " error" : ""}`}
            name="password"
            label="Password"
            placeholder="Password"
            type="password"
            setValues={setValues}
            values={values}
            onChange={inputChangeHandler}
            required
          />
          <CustomInput
            className={` ${errors?.password_confirmation ? " error" : ""}`}
            name="password_confirmation"
            label="Confirm Password"
            placeholder="Confirm Password"
            type="password"
            setValues={setValues}
            values={values}
            onChange={inputChangeHandler}
            required
          />
          {errors?.password_confirmation?.map((each) => (
            <p className="error_msg">{each}</p>
          ))}
          {errors?.password?.map((each) => (
            <p className="error_msg">{each}</p>
          ))}

          <div className="flex align-center gap-10">
            <div className="captha_cont">
              <img src={captchaCode} alt="" />
            </div>
            <CustomInput
              className={` ${errors?.password_confirmation ? " error" : ""}`}
              name="registrationCode"
              label="Captcha Code"
              placeholder="Enter Captcha"
              type="password"
              setValues={setValues}
              values={values}
              onChange={inputChangeHandler}
              required
            />
          </div>

          <div className="auth_buttons">
            <Button className="w-100" title="Register Now!" btnType="submit" />
            <Button
              isOutlined
              className="w-100"
              title="Continue as Guest"
              onClick={showGuestPopupHandler}
              btnType="button"
            />
            {/* <Button
              isOutlined
              className="w-100"
              title="Continue as Brand Owner"
              btnType="button"
            /> */}
          </div>
        </form>
        <div className=" sign-up-cont ">
          <div className=" p-cont flex align-center justify-center">
            <p className="mr-5 text-light">I'm already a member</p>
            <Link className="signup_link" to={`/signin`}>
              Sign In
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RegisterPage;
