import React from "react";
import Modal from "../../../Components/Modal/Modal";
import Button from "../../../Components/Button/Button";
import "./WinningPopup.scss";
import { useNavigate } from "react-router-dom";
import ConfettiWrapper from "../../../Components/ConfettiWrapper/ConfettiWrapper";
import CustomImage from "../../../Components/CustomImage/CustomImage";
import UseMyNavigate from "../../../Hooks/Common/UseMyNavigate";

const WinningPopup = ({
  prize_image,
  prize_amount,
  provider_name,
  provider_id,
  closeHandler,
}) => {
  const navigate = UseMyNavigate();
  const gotoWinningsClickHandler = () => {
    navigate(`/winnings/${provider_id}`);
  };
  return (
    <ConfettiWrapper>
      <Modal show>
        <div className="winning_popup">
          <div className="banner_image_cont">
            <CustomImage src={prize_image} alt="" />
          </div>
          <div className="middle">
            <p className="title">Hurray! You won INR {prize_amount}</p>
            <p className="description">
              To Claim the prize later go to {provider_name} section at nav,
              Enter your username and can claim
            </p>
          </div>
          <div className="bottom">
            <p onClick={closeHandler} className="close_btn pointer">
              Close
            </p>
            <Button
              onClick={gotoWinningsClickHandler}
              type="small"
              title="Go to my Winnings"
            />
          </div>
        </div>
      </Modal>
    </ConfettiWrapper>
  );
};

export default WinningPopup;
