import { getUrlParam } from "../../utils/utils";
import { createSearchParams, useNavigate } from "react-router-dom";
import UserRefreshLogin from "./UserRefreshLogin";

const UseMyNavigate = () => {
  const isLoggedin = UserRefreshLogin();

  const navigate = useNavigate();
  const customNavigate = (path) => {
    if (isLoggedin) {
      navigate(path);
    }
    const id = getUrlParam("id");
    const random_number = getUrlParam("random_number");
    let search = { id, random_number };
    const searchParams = createSearchParams(search)?.toString();
    if (random_number || id) {
      navigate(`${path}?${searchParams}`);
    } else {
      navigate(path);
    }
  };
  return customNavigate;
};

export default UseMyNavigate;
