import React from "react";

const Background3 = (props) => {
  return (
    <svg
      preserveAspectRatio="none"
      viewBox="0 0 174 198"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M0 167.155C0 182.088 0 189.554 4.71435 193.996C9.42871 198.437 16.8818 197.992 31.7881 197.102L138.238 190.746C150.969 189.986 157.334 189.605 161.369 185.542C165.403 181.479 165.738 175.111 166.408 162.375L173.242 32.4369C174.062 16.8304 174.473 9.02722 169.724 4.30003C164.975 -0.427155 157.174 0.018631 141.571 0.910202L28.2885 7.38351C14.8895 8.14917 8.19008 8.532 4.09504 12.8677C0 17.2034 0 23.9138 0 37.3347V167.155Z"
        fill="url(#paint0_linear_901_2850)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_901_2850"
          gradientUnits="userSpaceOnUse"
          x1="88"
          x2="88"
          y1="241.5"
          y2="-47.5"
        >
          <stop stopColor="#7226D2" />
          <stop offset="1" stopColor="#3A1B61" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default Background3;
