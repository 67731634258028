import React from "react";

const ImageSqueezeCont4 = (props) => {
  return (
    <svg
      viewBox="0 0 155 97"
      xmlns="http://www.w3.org/2000/svg"
      preserveAspectRatio="none"
      {...props}
    >
      <path
        d="M1.16887 17.7279C0.623044 9.4495 0.350129 5.3103 2.83399 2.75807C5.31784 0.205854 9.46293 0.36631 17.7531 0.687222L139.619 5.40464C146.893 5.68623 150.53 5.82703 152.765 8.14999C155 10.4729 155 14.1129 155 21.3927V80.3427C155 88.1674 155 92.0798 152.542 94.4409C150.084 96.802 146.175 96.6446 138.356 96.3298L20.3751 91.5789C13.5398 91.3036 10.1222 91.166 7.9254 89.0247C5.72857 86.8834 5.50354 83.4704 5.05348 76.6445L1.16887 17.7279Z"
        fill={`url(#pattern${props?.id})`}
      />
      <defs>
        <pattern
          height="1"
          id={`pattern${props?.id}`}
          width="1"
          patternContentUnits="objectBoundingBox"
        >
          <image
            height="144"
            width="256"
            transform="matrix(0.00434588 0 0 0.00694444 -0.0175627 0)"
            xlinkHref={props?.image}
            preserveAspectRatio="xMidYMid slice"
          />
        </pattern>
      </defs>
    </svg>
  );
};

export default ImageSqueezeCont4;
