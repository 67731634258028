import React from "react";

const Logo = (props) => {
  return (
    <svg
      height="20"
      width="102"
      fill="none"
      viewBox="0 0 102 20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="LuckyX3 logo (New)">
        <path
          id="Vector"
          d="M76.8908 0.628291L99.2212 0.434096C100.855 0.419951 102.011 1.51342 101.791 2.82938L99.424 16.9957C99.2037 18.3131 97.687 19.391 96.0533 19.391H73.8172C73.0216 19.391 71.0835 19.5307 70.8639 19.0592C70.6121 18.5183 70.0807 17.0849 70.1378 16.8207L72.3306 6.6761C72.4331 6.20188 73.8687 5.50278 74.6209 4.54284C75.4238 3.51841 76.0314 0.635723 76.8908 0.628291Z"
          fill="#671C74"
          fillRule="evenodd"
        />
        <path
          id="Vector_2"
          d="M80.7046 0.59528L83.3503 0.572266L74.7707 10.3982L73.1679 19.403L70.8916 19.4145L72.3799 10.3884L80.7046 0.59528Z"
          fill="#261723"
          fillOpacity="0.5"
          fillRule="evenodd"
        />
        <path
          id="Vector_3"
          d="M0 19.4145H12.1272L12.9576 14.6273H6.27583L8.71147 0.584981H3.26609L0.000237369 19.4145H0ZM15.1988 0.584981L13.3352 11.3296C12.8599 14.069 13.1751 16.1964 14.3062 17.7126C15.4374 19.2285 17.2242 19.9998 19.7232 19.9998C22.196 19.9998 24.2764 19.2285 25.9383 17.686C27.5954 16.1701 28.6485 14.0423 29.1235 11.303L30.9824 0.584981H25.9053L24.0464 11.303C23.5852 13.9625 22.4863 15.2924 20.7763 15.2924C18.9876 15.2924 18.3283 13.9361 18.7988 11.2232L20.644 0.584981H15.1986H15.1988ZM47.1208 1.51591C45.4547 0.505382 43.6745 0 41.7541 0C38.9393 0 36.4321 0.957542 34.2058 2.87238C31.9843 4.76061 30.622 7.15422 30.1285 9.99999C29.635 12.8458 30.1714 15.2128 31.7071 17.1276C33.2428 19.0425 35.4178 20 38.18 20C40.1267 20 41.9676 19.5478 43.7074 18.6169L45.3399 12.8458C43.2882 14.3617 41.4181 15.1329 39.761 15.1329C38.2879 15.1329 37.1871 14.6544 36.4541 13.7235C35.6948 12.7925 35.4643 11.5428 35.7365 9.97339C35.9995 8.45748 36.6327 7.23407 37.6358 6.30314C38.6389 5.37245 39.8269 4.89367 41.1687 4.89367C42.6681 4.89367 44.1483 5.6117 45.5609 7.02116L47.1208 1.51591ZM45.1111 19.4145H50.5564L51.8804 11.7818L55.7388 19.4145H62.3154L56.758 8.98924L64.9502 0.584981H58.8209L52.6878 7.12738L53.8225 0.584981H48.3771L45.1113 19.4145H45.1111ZM66.3825 19.4145H71.8279L73.3916 10.3987L81.8282 0.584981H76.1198L71.8189 5.66469L69.2801 0.584981H63.2822L67.9184 10.5584L66.3823 19.4148L66.3825 19.4145Z"
          fill="#C4179E"
        />
        <path
          id="Vector_4"
          d="M79.1099 5.13079C79.218 5.38156 79.371 5.57504 79.5249 5.81143C79.6762 6.04398 79.8037 6.23793 79.9496 6.46616C80.2889 6.9972 82.3904 10.1369 82.4867 10.4006L80.7056 13.1124C80.3952 13.5813 80.1246 13.9896 79.8118 14.4691C79.6176 14.7669 79.0553 15.5439 78.9579 15.8299L82.9036 15.822L84.592 13.0793C84.7471 13.4638 85.1773 14.0517 85.4085 14.4557C85.6511 14.8798 86.0405 15.4223 86.2297 15.8294L95.8714 15.7954C95.9103 15.2591 95.8859 14.6101 95.8907 14.0603C95.8933 13.775 95.8985 13.4842 95.8973 13.1994C95.8956 12.782 95.2207 11.8623 94.9988 11.4931L94.3483 10.4284C94.2452 10.2189 94.4749 10.0458 94.5885 9.88685L95.6386 8.37334C95.8257 8.10603 95.8729 8.14198 95.8736 7.73633L95.8731 5.12959L90.9845 5.15764C90.8159 5.35615 90.626 5.70834 90.4555 5.97973C90.1961 6.39305 89.0049 8.26689 88.9622 8.46563L92.4863 8.47739C92.4197 8.67302 91.1771 10.2783 91.1707 10.3555C91.1576 10.5121 92.4553 12.5559 92.5169 12.9057L88.3438 12.894C88.262 12.7194 88.0391 12.4094 87.9226 12.232C87.7773 12.0112 87.6587 11.8324 87.5093 11.6104C87.312 11.3167 86.8339 10.6353 86.7049 10.3553C86.9786 9.75163 89.975 5.52037 90.077 5.12719L86.2031 5.12767C86.0386 5.29454 85.2411 6.66371 85.017 7.03364C84.9005 7.22567 84.7858 7.4719 84.639 7.63828C84.4908 7.50282 83.3302 5.49089 83.1097 5.12695L79.1099 5.13055V5.13079Z"
          fill="white"
          fillRule="evenodd"
        />
      </g>
    </svg>
  );
};

export default Logo;
