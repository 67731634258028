import React, { useEffect, useState } from "react";
import WheelWithCss from "../../Components/Wheel/WheelWithCss";
import Button from "../../Components/Button/Button";
import "./LuckyDrawDetail.scss";
import WinningPrizeCard from "../../Components/WinningPrizeCard/WinningPrizeCard";
import WinningPopup from "./WinningPopup/WinningPopup";
import { useParams } from "react-router-dom";
import ScratchCardComp from "../../Components/ScratchCard/ScratchCardComp";
import UseApiError from "../../Hooks/Common/UseApiError";
import { useDispatch, useSelector } from "react-redux";
import { clearLuckydrawError, fetchLuckydraw } from "../../redux/homePageSlice";
import { FILE_BASE_URL } from "../../config/config";
import axios from "axios";
import { getUniqueToken, getUrlParam, stringTrim } from "../../utils/utils";
import { toast } from "react-toastify";
import CustomImage from "../../Components/CustomImage/CustomImage";
import CaptchaPopup from "./CaptchaPopup/CaptchaPopup";
import Loader from "../../Components/Loader/Loader";

const LuckyDrawDetail = () => {
  const [spin, setSpin] = useState(false);
  const [showWinningsModal, setShowWinningsModal] = useState(false);
  const [winningPrize, setWinningPrize] = useState(null);
  const [rotationDegree, setRotateDegree] = useState(0);

  let { id } = useParams();
  const { error, detail, isLoading } = useSelector((store) => store.luckydraw);
  const luckydrawData = detail?.LuckyDraw;
  let type = luckydrawData?.type;
  if (!type) {
    type = getUrlParam("type");
  }
  const { user } = useSelector((store) => store.user);

  const dispatch = useDispatch();
  UseApiError(error, clearLuckydrawError);

  useEffect(() => {
    const token = getUniqueToken();
    if (token || id) dispatch(fetchLuckydraw(token || id));
  }, []);

  const spinClickHandler = () => {
    let isScratch = type === "scratch";
    if (!user) {
      setShowCaptcha(true);
      return;
    }
    const token = getUniqueToken();
    if (!spin && token) {
      setSpin(true);
      axios
        .post(`/spinuser/${token}`)
        .then((res) => {
          const data = res?.data?.data;
          setTimeout(() => {
            if (data?.prize) {
              setWinningPrize(data?.prize);
              if (!isScratch) {
                // setShowWinningsModal(true);
              } else {
                setTimeout(() => {
                  setShowWinningsModal(true);
                }, 400);
              }
            } else {
              toast.error(data?.message);
            }
            setSpin(false);
          }, 2000);
        })
        .catch((e) => {
          setSpin(false);
          toast.error(
            e?.response?.data?.message || "Spin Error , Try Again later."
          );
        });
    }
  };
  const onSuccessScratch = () => {
    spinClickHandler(true);
  };

  const [showcaptcha, setShowCaptcha] = useState(false);
  const closeCaptchaHandler = () => {
    setShowCaptcha(false);
    const token = getUniqueToken();
    dispatch(fetchLuckydraw(token || id));
  };

  console.log(winningPrize, "winning prize", showcaptcha);
  return (
    <div className="page luckydraw_detail">
      {isLoading && <Loader />}
      {showcaptcha && (
        <CaptchaPopup
          closeHandler={closeCaptchaHandler}
          setShowWinningsModal={setShowWinningsModal}
          setSpin={setSpin}
          setWinningPrize={setWinningPrize}
          spin={spin}
        />
      )}
      {showWinningsModal && (
        <WinningPopup
          prize_amount={winningPrize?.amount || 0}
          prize_image={winningPrize?.image}
          provider_id={winningPrize?.admin_id}
          provider_name={luckydrawData?.user?.name}
          closeHandler={() => {
            setShowWinningsModal(false);
            setWinningPrize(null);
            const token = getUniqueToken();
            dispatch(fetchLuckydraw(token || id));
          }}
        />
      )}
      <div className="provider_cont">
        <div className="provider_img">
          <CustomImage src={luckydrawData?.user?.avatar} alt="" />
        </div>
        <div className="provider_info">
          <p className="title">{luckydrawData?.name || ""}</p>
          <p className="description">
            {stringTrim(luckydrawData?.description, 70)}
          </p>
        </div>
      </div>
      {type === "spinner" && (
        <WheelWithCss
          winningPrize={winningPrize}
          isSpin={spin}
          wheelData={luckydrawData?.prizes}
          setShowWinningsModal={setShowWinningsModal}
          rotationDegree={rotationDegree}
          setRotateDegree={setRotateDegree}
        />
      )}
      {type === "scratch" && !showWinningsModal && (
        <ScratchCardComp
          winningPrize={winningPrize}
          onSuccessScratch={onSuccessScratch}
        />
      )}
      {/* {type == "scratch" && <h2>Scratch card work in progress</h2> } */}

      {type === "spinner" && (
        <Button
          className="w-100 spin_btn"
          title="Spin Wheel"
          onClick={spinClickHandler}
          loading = {spin}
        />
      )}
      <div className="spin_left">
        <p>
          {detail?.RemainingSpins || 0}{" "}
          {type === "spinner" ? " Spin" : " Scratch"} Left
        </p>
      </div>
      <div className="winning_prize_list">
        <p className="section_title">Winning Prizes List</p>
        <div className="winning_prizes">
          {luckydrawData?.prizes?.map((each, i) => (
            <WinningPrizeCard
              key={each.id}
              index={i}
              data={each}
              id={each.id}
              image={`${FILE_BASE_URL}${each.image}`}
              possibility={each.possibility || ""}
              title={each.name || ""}
              reward_amount={each.amount || 0}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default LuckyDrawDetail;
