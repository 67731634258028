import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { clearFieldErrors, signInUser } from "../../redux/userSlice";
import "./LoginPage.scss";
import { toast } from "react-toastify";
import Loader from "../../Components/Loader/Loader";
import { useEffect } from "react";
import Button from "../../Components/Button/Button";
import CustomInput from "../../Components/CustomInput/CustomInput";
import ResetPasswordPopup from "../RegisterPage/Popups/ResetPasswordPopup";
import GuestConfirmationPopup from "../RegisterPage/Popups/GuestConfirmationPopup";
import GoBackButton from "../../Components/GoBackButton/GoBackButton";
import Logo from "../../assets/svgs/Logo";
import { getUrlParam } from "../../utils/utils";

const LoginPage = () => {
  const [values, setValues] = useState({

  });
  const [errors, setErrors] = useState({});

  const dispatch = useDispatch();
  const { isLoading, error, field_errors } = useSelector((store) => store.user);
  const navigate = useNavigate();

  const inputChangeHandler = (e) => {
    let name = e.target.name;
    if (errors[name]) {
      setErrors({ ...errors, [name]: null });
    }
    setValues({
      ...values,
      [e.target.name]: e.target.value,
    });
  };

  const formSubmitHandler = (e) => {
    e.preventDefault();
    console.log(values, "some values");
    dispatch(signInUser(values));
  };

  useEffect(() => {
    if (error || field_errors) {
      if (field_errors) setErrors(field_errors);
      if (error) toast.error(error);
      dispatch(clearFieldErrors());
    }
  }, [error, field_errors]);

  const [showResetPopup, setShowResetPopup] = useState(false);

  const showResetPopupHandler = () => {
    setShowResetPopup(true);
  };
  const hideResetPopupHandler = () => {
    setShowResetPopup(false);
  };

  const [showGuestPopup, setShowGuestPopup] = useState(false);
  const showGuestPopupHandler = () => {
    setShowGuestPopup(true);
  };
  const hideGuestPopupHandler = () => {
    setShowGuestPopup(false);
  };

  const random_number = getUrlParam("random_number");

  return (
    <>
      <GoBackButton />
      {showGuestPopup && (
        <GuestConfirmationPopup closer={hideGuestPopupHandler} />
      )}
      {showResetPopup && <ResetPasswordPopup closer={hideResetPopupHandler} />}
      <Loader loading={isLoading} />
      <div className="login-page page ">
        <div className="bottom-cont ">
          <div className="logo_cont">
            <Logo />
          </div>

          <p className="form-heading">Welcome Back</p>
          <p className="text_light">Sign in to continue!</p>
          <form className="login_form" onSubmit={formSubmitHandler}>
            <div className="flex flex-column ">
              <CustomInput
                className={` ${errors?.email ? " error" : ""}`}
                name="email"
                label="email or Email"
                placeholder="email or Email"
                type="text"
                setValues={setValues}
                values={values}
                onChange={inputChangeHandler}
                required
              />
              {errors?.email?.map((each) => (
                <p className="error_msg">{each}</p>
              ))}
            </div>

            <CustomInput
              className={` ${errors?.password ? " error" : ""}`}
              name="password"
              label="Password"
              placeholder="Password"
              type="password"
              setValues={setValues}
              values={values}
              onChange={inputChangeHandler}
              required
            />
            <div
              onClick={showResetPopupHandler}
              className="flex justify-end mb-20 forgot_link "
            >
              <p className="mr-5 text-light">Forgot Password?</p>
            </div>
            <div className="auth_buttons">
              <Button className="w-100" title="Login" />
              {random_number && (
                <Button
                  btnType="button"
                  isOutlined
                  className="w-100"
                  title="Continue as Guest"
                  onClick={showGuestPopupHandler}
                />
              )}
            </div>
          </form>
          <div className=" sign-up-cont ">
            <div className=" p-cont flex align-center justify-center">
              <p className="mr-5 text-light">I'm a new user</p>
              <Link className="signup_link" to={`/register`}>
                Sign Up
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default LoginPage;
