import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";

const UseApiError = (error, clearErrorAction) => {

  const dispatch = useDispatch();
  useEffect(() => {
    if (error) {
      toast.error(error);
      dispatch(clearErrorAction());
    }
  }, [error]);
};

export default UseApiError;
