import React from "react";

const ImageSquezeCont = (props) => {
  return (
    <svg
      height="97"
      width="150"
      viewBox="0 0 150 97"
      xmlns="http://www.w3.org/2000/svg"
      preserveAspectRatio="none"
      {...props}
    >
      <path
        d="M0 16.6806C0 8.8459 0 4.92857 2.46215 2.56687C4.92429 0.20518 8.83823 0.368262 16.6661 0.694426L129.646 5.40195C136.472 5.68637 139.885 5.82858 142.078 7.96919C144.271 10.1098 144.496 13.5183 144.945 20.3354L148.83 79.2491C149.376 87.5372 149.649 91.6813 147.162 94.2341C144.674 96.787 140.524 96.621 132.225 96.289L15.3605 91.6144C8.0951 91.3238 4.46241 91.1785 2.23121 88.8563C0 86.534 0 82.8984 0 75.6272V16.6806Z"
        fill={`url(#pattern${props.id})`}
      />
      <defs>
        <pattern
          height="1"
          id={`pattern${props.id}`}
          width="1"
          patternContentUnits="objectBoundingBox"
        >
          <image
            height="190"
            width="280"
            transform="matrix(0.00357143 0 0 0.00634921 0 -0.0222222)"
            xlinkHref={props.image}
            preserveAspectRatio="xMidYMid slice"
          />
        </pattern>
      </defs>
    </svg>
  );
};

export default ImageSquezeCont;
