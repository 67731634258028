import React, { useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import axios from "axios";
import { useEffect } from "react";
import Loader from "../../Components/Loader/Loader";
import TextField from "../../Components/Common/Input/TextField";

const ResetPassword = () => {
  const [values, setValues] = useState({});
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const { search } = useLocation()
  const navigate = useNavigate();

  const cb = (success, res) => {
    setLoading(false);
    if (success) {
      toast.success(res?.data?.message || "Password Changed Successfully");
      navigate("/signin");
    } else {
      if (res?.data?.errors) {
        setErrors(res?.data?.errors);
        return;
      }
      toast.error(
        res?.data?.message || res?.message || "Something went wrong, Try again"
      );
    }
  };

  const inputChangeHandler = (e) => {
    let name = e?.target?.name;
    let value = e?.target?.value;
    if (errors[name]) {
      setErrors({ ...errors, [name]: null });
    }
    if (errors?.confirm_password && name === "confirm_password") {
      if (values.password == value) {
        setErrors({
          ...errors,
          confirm_password: null,
        });
      } else {
        setErrors({
          ...errors,
          confirm_password: ["Password and Confirm password not matched"],
        });
      }
    }
    setValues({
      ...values,
      [e.target.name]: e.target.value,
    });
  };

  const formSubmitHandler = (e) => {
    e.preventDefault();
    let payload = { ...values };
    if (payload.password !== payload.confirm_password) {
      setErrors({
        ...errors,
        confirm_password: ["Password and Confirm password not matched"],
      });
      return;
    }
    setLoading(true);
    axios
      .post("/password-update", payload)
      .then((res) => {
        if (res?.data?.response) {
          cb(true, res);
        } else {
          cb(false, res);
        }
      })
      .catch((e) => {
        cb(false, e?.response?.data);
      });
  };

  useEffect(() => {
    let params = new URLSearchParams(search)
    let token = params.get('token');
    let email = params.get('email');
    setValues({ token, email });
  }, []);

  return (
    <>
      <Loader loading={loading} />

      <div className="login-page">
        <div onClick={() => navigate("/")} className="logo-cont ">
          <img
            src={require("../../assets/logo.png")}
            alt=""
            onClick={() => navigate("/")}
            className="bonus_logo"
          />
        </div>
        <div className="bottom-cont">
          <form onSubmit={formSubmitHandler}>
            <p className="form-heading text-center">Create New Password</p>
            <TextField
              name="email"
              label="Email"
              type="text"
              setValues={setValues}
              values={values}
              onChange={inputChangeHandler}
              required
              disabled
            />
            <div className="flex flex-column">
              <TextField
                className={` ${errors?.password ? " error" : ""}`}
                name="password"
                label="New Password"
                type="password"
                setValues={setValues}
                values={values}
                required
                onChange={inputChangeHandler}
              />
              {errors?.password?.map((each) => (
                <p className="error_msg">{each}</p>
              ))}
            </div>
            <div className="flex flex-column">
              <TextField
                className={` ${errors?.password ? " error" : ""}`}
                name="confirm_password"
                label="Confirm New Password"
                type="password"
                setValues={setValues}
                values={values}
                required
                onChange={inputChangeHandler}
              />
              {errors?.confirm_password?.map((each) => (
                <p className="error_msg">{each}</p>
              ))}
            </div>
            <div className="flex justify-center">
              <Link to="/signin" className="mr-5 text-light">
                Back to sign in ?
              </Link>
            </div>
            <button className="btn btn-primary">Reset Password</button>
          </form>
        </div>
      </div>
    </>
  );
};

export default ResetPassword;
