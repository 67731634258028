import React from "react";
import Modal from "../../../Components/Modal/Modal";
import Button from "../../../Components/Button/Button";
import ResetLockIcon from "./ResetLockIcon";
import CustomInput from "../../../Components/CustomInput/CustomInput";

const ResetPasswordPopup = ({ closer }) => {
  const resetPasswordHandler = () => {
    alert("reset continue clicked");
    closer();
  };
  return (
    <Modal show>
      <div className="reset_pass_popup">
        <div className="icon">
          <ResetLockIcon />
        </div>
        <p className="main_title">Reset Password</p>
        <CustomInput placeholder="Enter Register email" />
        <p className="description">
          Reset link will sent to your registered email, You can reset from it
        </p>
        <div className="bottom">
          <p onClick={closer} className="close_btn pointer">
            Close
          </p>
          <Button
            onClick={resetPasswordHandler}
            type="small"
            title="Continue"
          />
        </div>
      </div>
    </Modal>
  );
};

export default ResetPasswordPopup;
