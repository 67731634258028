import React, { useState } from "react";
import "./NavBar.scss";
import SideNav from "../SideNav/SideNav";
import { useLocation, } from "react-router-dom";
import { useSelector } from "react-redux";
import axios from "axios";
import { useMemo } from "react";
import Menu from "../../assets/svgs/Menu";
import Notification from "../../assets/svgs/Notification";
import Logo from "../../assets/svgs/Logo";
import UseMyNavigate from "../../Hooks/Common/UseMyNavigate";
import { getUrlParam } from "../../utils/utils";

const hideNavRoutes = ["/signin", "/register"];

const NavBar = () => {
  const [showSideNav, setShowSideNav] = useState(false);
  const { pathname } = useLocation();

  const openSideNav = () => {
    setShowSideNav(true);
  };
  const closeSideNav = () => {
    setShowSideNav(false);
  };
  const navigate = UseMyNavigate();
  const { user } = useSelector((store) => store?.user?.user || {});
  const notifyClickHandler = () => {
    setTimeout(() => {
      axios
        .get(`/clear-all/${user?.id}`)
        .then((res) => {})
        .catch((e) => {});
    }, 2000);

    navigate("/notifications");
  };
  const { data: notifcations } = useSelector(
    (store) => store?.notifications || {}
  );
  const notificationCount = useMemo(() => {
    let latestNotifications = notifcations?.filter((each) => each.status);
    return latestNotifications?.length;
  }, [notifcations]);

  const random_number = getUrlParam("random_number");

  const navigateToHome = () =>{
    if(random_number){
      navigate('/luckydraw')
    }
    else {
      navigate('/')
    }
  }


  return hideNavRoutes?.includes(pathname) ? null : (
    <>
      <SideNav closer={closeSideNav} show={showSideNav} />
      <div className="sticky_top_nav">
        <nav className="root_nav_bar">
          <div className="flex align-center">
            <div onClick={openSideNav} className="icon_cont hamicon">
              <Menu />
            </div>

            <div onClick={navigateToHome} className="logo-container">
              <Logo />
            </div>
          </div>

          {/* <div
            onClick={notifyClickHandler}
            className="icon_cont notification_icon relative"
          >
            {notificationCount ? (
              <p className="badge">{notificationCount}</p>
            ) : null}
            <Notification />
          </div> */}
        </nav>
      </div>
    </>
  );
};

export default NavBar;
