import React from "react";
import "./CustomInput.scss";

const CustomInput = ({
  name,
  placeholder,
  onChange,
  values,
  required,
  disabled,
  value,
}) => {
  return (
    <>
      <input
        className={`cutom_input ${values?.[name] && "value"}`}
        name={name}
        placeholder={placeholder}
        onChange={onChange}
        value={value ? value : values?.[name] || ""}
        type="text"
        required={required}
        disabled={disabled}
      />
    </>
  );
};

export default CustomInput;
