export const winningsData = {
    provider_img: "https://images.unsplash.com/photo-1562307534-a03738d2a81a?w=200&q=80",
    provider_name: "Dafabet",
    username: null,
    unclaimedData: [
        {
            id: 1,
            image: "https://images.unsplash.com/photo-1504279807002-09854ccc9b6c",
            title: "Bronze Reward",
            possibility: 20,
            reward_amount: 50
        },
        {
            id: 2,
            image: "https://images.unsplash.com/photo-1504279807002-09854ccc9b6c",
            title: "Silver Reward",
            possibility: 30,
            reward_amount: 75
        },
        {
            id: 3,
            image: "https://images.unsplash.com/photo-1504279807002-09854ccc9b6c",
            title: "Gold Reward",
            possibility: 10,
            reward_amount: 100
        },
        {
            id: 4,
            image: "https://images.unsplash.com/photo-1504279807002-09854ccc9b6c",
            title: "Platinum Reward",
            possibility: 15,
            reward_amount: 150
        },
    ],
    claimedData: [
        {
            id: 4,
            image: "https://images.unsplash.com/photo-1504279807002-09854ccc9b6c",
            title: "Platinum Reward",
            possibility: 15,
            reward_amount: 150
        },
        {
            id: 5,
            image: "https://images.unsplash.com/photo-1504279807002-09854ccc9b6c",
            title: "Diamond Reward",
            possibility: 25,
            reward_amount: 200
        }
    ]
}


export const tabsData = [
    {
        id: 1,
        title: "My Winnings",
        type: "my_winnings"
    },
    {
        id: 2,
        title: "Claimed",
        type: "claimed"
    },

]