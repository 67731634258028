import React from "react";

const SmallCircle = (props) => {
  return (
    <svg
      height="656"
      width="656"
      fill="none"
      viewBox="0 0 656 656"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g>
        <circle cx="328" cy="328" fill="#EE2AF2" fillOpacity="0.3" r="128" />
      </g>
      <defs />
    </svg>
  );
};

export default SmallCircle;
