import React from "react";

const Menu = (props) => {
  return (
    <svg
      height="36"
      width="36"
      fill="none"
      viewBox="0 0 36 36"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        height="35"
        width="35"
        rx="17.5"
        stroke="white"
        strokeOpacity="0.2"
        x="0.5"
        y="0.5"
      />
      <rect height="6" width="6" fill="white" rx="3" x="11" y="11" />
      <rect height="6" width="6" fill="white" rx="3" x="11" y="19" />
      <rect height="6" width="6" fill="white" rx="3" x="19" y="11" />
      <rect height="6" width="6" fill="white" rx="3" x="19" y="19" />
    </svg>
  );
};

export default Menu;
