import React, { useState } from "react";
import "./WithdrawPopup.scss";
import Modal from "../../../Components/Modal/Modal";
import CustomInput from "../../../Components/CustomInput/CustomInput";
import Button from "../../../Components/Button/Button";
import axios from "axios";
import { toast } from "react-toastify";
import { DEFAULT_SERVE_ERROR, getUrlParam } from "../../../utils/utils";

const WithdrawPopup = ({
  closeHandler,
  data,
  fetchWinningsByProviderid,
  savedUserName,
  winnings,
}) => {
  const [loading, setLoading] = useState(false);

  const [username, setUsername] = useState(savedUserName);
  const withdrawPrizeHandler = () => {
    if (!username && !savedUserName) {
      toast.error("Please fill Username");
      return;
    }
    let route = `/digital-prize-claim`;
    let random_number = getUrlParam("random_number");
    if (random_number) route = `/digital-prize-claim-guest`;
    setLoading(true);
    let payload = {
      claim_details: {
        username: username || savedUserName,
      },
      prizeId: data.prize_id,
      random_number,
    };
    axios
      .post(route, payload)
      .then((res) => {
        setLoading(false);
        toast.success("Claim request Sent ");
        fetchWinningsByProviderid();
        closeHandler();
      })
      .catch((e) => {
        setLoading(false);
        toast.error(e?.response?.data?.message || DEFAULT_SERVE_ERROR);
        console.log(e);
      });
  };
  const registerClickHandler = () => {
    let website_url = winnings?.provider?.website || "";
    if (!website_url) {
      alert("No website url found");
      return;
    }
    window.open(website_url);
  };
  return (
    <Modal className="withdrawpopup-modal" show>
      <div className="withdrawpopup">
        <p className="fill_text">Please Fill in your</p>
        <p className="bold_title">
          Username of {winnings?.provider?.name || ""}
        </p>
        <CustomInput
          onChange={(e) => setUsername(e.target.value || "")}
          placeholder="Enter username here"
          value={username}
          name={"username"}
          values={{ username: username }}
          disabled={false}
        />

        {!savedUserName ||
          (true && (
            <p style={{ lineHeight: 1.5 }} className="fill_text click_here">
              Don't have account in {winnings?.provider?.name} <br />
              <span onClick={registerClickHandler} className="link">
                click here
              </span>{" "}
              to Register
            </p>
          ))}

        <div style={{ marginTop: "20px" }} className="bottom">
          <p onClick={closeHandler} className="close_btn pointer">
            Close
          </p>
          <Button
            onClick={withdrawPrizeHandler}
            type="small"
            title="Withdraw Prize"
          />
        </div>
      </div>
    </Modal>
  );
};

export default WithdrawPopup;
