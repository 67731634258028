export const invitedLuckyDraws = [
  {
    "type": "spinner",
    "banner_img": require("./images/banner_1.png"),
    "brand_img": require("./images/provider_banner_1.png"),
    "title": "Lucky Spinner",
    "description": "Some Description Motorola Gadgets for 1 in 1000 Winners. Cool Lucky draw asdd dds how are u......",
    "created_at": "2023-08-03T12:00:00",
    "spins": 3,
     "id" : 1
  },
  {
    "type": "scratch",
    "banner_img": require("./images/banner_2.png"),
    "brand_img": require("./images/provider_banner_2.png"),
    "title": "Scratch Card Bonanza",
    "description": "Scratch and reveal your surprise reward!",
    "created_at": "2023-08-02T14:30:00",
    "spins": 1,
    "id" : 2

  },
  {
    "type": "spinner",
    "banner_img": require("./images/banner_1.png"),
    "brand_img": require("./images/provider_banner_1.png"),
    "title": "Spin and Win",
    "description": "Try your luck with the spinning wheel!",
    "created_at": "2023-08-01T09:15:00",
    "spins": 2,
    "id" : 3
  },
  {
    "type": "scratch",
    "banner_img": require("./images/banner_2.png"),
    "brand_img": require("./images/provider_banner_2.png"),
    "title": "Super Scratchers",
    "description": "Unleash the prizes hidden beneath the scratch card!",
    "created_at": "2023-07-31T18:45:00",
    "spins": 1,
    "id" : 4
  }
];
