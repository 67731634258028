export const historyData = [
    {
      "provider_logo": "https://cdn.logo.com/hotlink-ok/logo-social.png",
      "title": "Summer Fun Draw",
      "created_at": "2023-08-10 15:30:00",
      "count": 1,
      "is_debit": true,
      "debit_type": "debit",
      "luckydraw_type": "spinner"
    },
    {
      "provider_logo": "https://cdn.logo.com/hotlink-ok/logo-social.png",
      "title": "Lucky Scratch Off",
      "created_at": "2023-08-15 10:45:00",
      "count": 5,
      "is_debit": false,
      "debit_type": "",
      "luckydraw_type": "scratch"
    },
    {
      "provider_logo": "https://cdn.logo.com/hotlink-ok/logo-social.png",
      "title": "Weekend Spin",
      "created_at": "2023-08-18 19:20:00",
      "count": 4,
      "is_debit": true,
      "debit_type": "expired",
      "luckydraw_type": "spinner"
    },
    {
      "provider_logo": "https://cdn.logo.com/hotlink-ok/logo-social.png",
      "title": "Quick Spin",
      "created_at": "2023-08-20 08:00:00",
      "count": 3,
      "is_debit": true,
      "debit_type": "debit",
      "luckydraw_type": "spinner"
    },
    {
      "provider_logo": "https://cdn.logo.com/hotlink-ok/logo-social.png",
      "title": "Mega Jackpot",
      "created_at": "2023-08-25 14:15:00",
      "count": 3,
      "is_debit": false,
      "debit_type": "",
      "luckydraw_type": "spinner"
    },
    {
      "provider_logo": "https://cdn.logo.com/hotlink-ok/logo-social.png",
      "title": "Prize Frenzy",
      "created_at": "2023-08-28 11:30:00",
      "count": 6,
      "is_debit": true,
      "debit_type": "expired",
      "luckydraw_type": "scratch"
    },
    {
      "provider_logo": "https://cdn.logo.com/hotlink-ok/logo-social.png",
      "title": "Daily Spin",
      "created_at": "2023-09-02 09:45:00",
      "count": 1,
      "is_debit": true,
      "debit_type": "debit",
      "luckydraw_type": "spinner"
    },
    {
      "provider_logo": "https://cdn.logo.com/hotlink-ok/logo-social.png",
      "title": "Instant Win",
      "created_at": "2023-09-05 16:10:00",
      "count": 7,
      "is_debit": false,
      "debit_type": "",
      "luckydraw_type": "scratch"
    },
    {
      "provider_logo": "https://cdn.logo.com/hotlink-ok/logo-social.png",
      "title": "Treasure Hunt",
      "created_at": "2023-09-08 12:20:00",
      "count": 1,
      "is_debit": true,
      "debit_type": "debit",
      "luckydraw_type": "spinner"
    },
    {
      "provider_logo": "https://cdn.logo.com/hotlink-ok/logo-social.png",
      "title": "Gold Rush",
      "created_at": "2023-09-12 17:40:00",
      "count": 9,
      "is_debit": false,
      "debit_type": "",
      "luckydraw_type": "scratch"
    }
  ]
  