import React from "react";

const Background4 = (props) => {
  return (
    <svg
      preserveAspectRatio="none"
      viewBox="0 0 174 198"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M174 167.155C174 182.088 174 189.554 169.286 193.996C164.571 198.437 157.118 197.992 142.212 197.102L35.7625 190.746C23.0312 189.986 16.6655 189.605 12.6311 185.542C8.59671 181.479 8.2618 175.111 7.59198 162.375L0.758469 32.4369C-0.0622864 16.8304 -0.472656 9.02722 4.27603 4.30003C9.02472 -0.427155 16.826 0.018631 32.4286 0.910202L145.712 7.38351C159.11 8.14917 165.81 8.532 169.905 12.8677C174 17.2034 174 23.9138 174 37.3347V167.155Z"
        fill="url(#paint0_linear_901_2840)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_901_2840"
          gradientUnits="userSpaceOnUse"
          x1="87"
          x2="87"
          y1="229"
          y2="-49.5"
        >
          <stop stopColor="#218C4B" />
          <stop offset="1" stopColor="#10361F" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default Background4;
