import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';
import { Provider } from 'react-redux';
import { store } from './redux/store';
import AllRoutes from './Routes/Routes'
import setupAxios from './redux/setupAxios';
import axios from 'axios';
import PWAInstaller from './Components/Common/PwaIntaller';
import MobileWidthLayout from './Components/MobileWidthLoyout/MobileWidthLayout';

setupAxios(axios, store)

function App() {

  return (
    <div id='app' className="App" >
      {/* <PWAInstaller /> */}
      <ToastContainer />
      <Provider store={store}>
        <BrowserRouter>
          <MobileWidthLayout>
            <AllRoutes />
          </MobileWidthLayout>
        </BrowserRouter>
      </Provider>
    </div>
  );
}

export default App;
