import React from "react";

const Background2 = (props) => {
  return (
    <svg
      preserveAspectRatio="none"
      viewBox="0 0 174 198"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M174 30.8447C174 15.9119 174 8.4455 169.286 4.00425C164.571 -0.437008 157.118 0.00802195 142.212 0.898082L35.7625 7.25422C23.0312 8.01441 16.6655 8.3945 12.6311 12.4577C8.59671 16.5208 8.2618 22.889 7.59198 35.6253L0.758469 165.563C-0.0622864 181.17 -0.472656 188.973 4.27603 193.7C9.02472 198.427 16.826 197.981 32.4286 197.09L145.712 190.616C159.11 189.851 165.81 189.468 169.905 185.132C174 180.797 174 174.086 174 160.665V30.8447Z"
        fill="url(#paint0_linear_901_2829)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_901_2829"
          gradientUnits="userSpaceOnUse"
          x1="87"
          x2="87"
          y1="-7.5"
          y2="217"
        >
          <stop stopColor="#13BFB5" />
          <stop offset="1" stopColor="#1C4A48" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default Background2;
