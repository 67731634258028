import React from "react";
import "./Tabs.scss";

const Tabs = ({ tabsData, activeTab, tabClickHandler }) => {
  return (
    <div className="tabs">
      {tabsData?.map((each) => (
        <div
          onClick={() => tabClickHandler(each)}
          id={each.id}
          className={`tab ${activeTab?.id == each.id && "active"}`}
        >
          <p className="title">{each.title}</p>
        </div>
      ))}
    </div>
  );
};

export default Tabs;
