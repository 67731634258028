import React from "react";
import "./MobileWidthLayout.scss";
import SmallCircle from "./SmallCircle";
import MiddleTint from "./MiddleTint";
import BottomTint from "./BottomTint";

const MobileWidthLayout = (props) => {
  return (
    <div className="MobileWidthLayout">
      <div className="small_circle absolute">
        <SmallCircle />
      </div>
      <div className="middle_circle absolute">
        <MiddleTint />
      </div>
      <div className="bottom_circle absolute">
        <BottomTint />
      </div>
      {props.children}
    </div>
  );
};

export default MobileWidthLayout;
