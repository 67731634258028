import React from "react";

const BottomTint = (props) => {
  return (
    <svg
      height="266"
      width="250"
      fill="none"
      viewBox="0 0 250 266"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g>
        <rect
          height="550"
          width="270"
          fill="#EE2AF2"
          fillOpacity="0.3"
          rx="135"
          x="-220"
          y="200"
        />
      </g>
      <defs />
    </svg>
  );
};

export default BottomTint;
