
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios'

const initialState = {
    data: null,
    isLoading: false,
    detail: null,
    error: null,
    winHistory : null
}

export const fetchAllLuckydraws = createAsyncThunk('fetchLuckyDraws', (queryString, { rejectWithValue }) => {
    return axios.get(`/invitation/user-details`).then(res => res.data)
});
export const fetchLuckydraw = createAsyncThunk('fetchLuckydraw', (id, { rejectWithValue }) => {
    return axios.get(`/luckyDraw/${id}`).then(res => res.data)
});



export const homePageSlice = createSlice({
    name: 'dashboard',
    initialState,
    reducers: {
        clearDetailLuckydraw: (state) => {
            state.detail = null;
        },
        clearLuckydrawError: (state, action) => {
            state.error = null;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(fetchAllLuckydraws.pending, (state) => {
            state.isLoading = true
        })
        builder.addCase(fetchAllLuckydraws.fulfilled, (state, action) => {
            state.isLoading = false
            state.data = action.payload?.data;
        })
        builder.addCase(fetchAllLuckydraws.rejected, (state, action) => {
            state.isLoading = false
            state.error = action.error.message;
        })

        //////////// FETCH SINGLE BLOG ////////////////
        builder.addCase(fetchLuckydraw.pending, (state) => {
            state.isLoading = true
        })
        builder.addCase(fetchLuckydraw.fulfilled, (state, action) => {
            state.isLoading = false
            state.detail = action.payload?.data;
        })
        builder.addCase(fetchLuckydraw.rejected, (state, action) => {
            state.isLoading = false
            state.error = action.error.message;
        })


 
    }
})

export default homePageSlice.reducer;

// SELECTORS 
export const selectHomepageStore = (store) => store.luckydraw

export const { clearLuckydrawError, clearDetailLuckydraw } = homePageSlice.actions;



