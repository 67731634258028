import React from "react";
import { Assets } from "../../assets/data";
import CustomImage from "../CustomImage/CustomImage";
import UseMyNavigate from "../../Hooks/Common/UseMyNavigate";

const ProviderButton = ({ provider_logo, prizes_count, id ,closer}) => {
  const navigate = UseMyNavigate()
  const providerButtonHandler = () => {
    navigate(`/winnings/${id}`)
    closer()
  }
  return (
    <div onClick={providerButtonHandler} className="provider_btn_root">
      <div className="flex align-center">
        <div className="provider_icon">
          <CustomImage src={provider_logo} alt="" />
        </div>
        <div className="prizes_count_cont">
          <p>Prizes Won</p>
          <p>{prizes_count || 0}</p>
        </div>
      </div>
      <div className="icon_cont chevron">{Assets.svgs.ArrowIcon}</div>
    </div>
  );
};

export default ProviderButton;
