import React from "react";

const ArrowIcon = (props) => {
  return (
    <svg
      height="32"
      width="32"
      fill="none"
      viewBox="0 0 32 32"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="arrow icon ">
        <g id="1">
          <rect
            height="30"
            width="30"
            fill="white"
            fillOpacity="0.01"
            rx="15"
            transform="matrix(-1 0 0 1 31 1)"
          />
          <rect
            height="31"
            width="31"
            rx="15.5"
            stroke="white"
            strokeOpacity="0.05"
            transform="matrix(-1 0 0 1 32 1)"
            x="0.5"
            y="-0.5"
          />
        </g>
        <path
          id="Vector"
          d="M13.3824 21.8333L18.1846 17.0311C18.7518 16.464 18.7518 15.5359 18.1846 14.9688L13.3824 10.1666"
          stroke="white"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          strokeWidth="1.5"
        />
      </g>
      <defs />
    </svg>
  );
};

export default ArrowIcon;
