import React from "react";

const ImageSqueezeCont3 = (props) => {
  return (
    <svg
      viewBox="0 0 155 97"
      xmlns="http://www.w3.org/2000/svg"
      preserveAspectRatio="none"
      {...props}
    >
      <path
        d="M0 21.3926C0 14.1128 0 10.4729 2.23478 8.1499C4.46956 5.82694 8.10674 5.68615 15.3811 5.40456L137.247 0.687211C145.537 0.366304 149.682 0.20585 152.166 2.75807C154.65 5.31029 154.377 9.44949 153.831 17.7279L149.947 76.6444C149.496 83.4704 149.271 86.8833 147.075 89.0246C144.878 91.1659 141.46 91.3036 134.625 91.5788L16.6438 96.3297C8.82542 96.6446 4.91624 96.802 2.45812 94.4409C0 92.0797 0 88.1674 0 80.3427V21.3926Z"
        fill={`url(#pattern${props?.id})`}
      />
      <defs>
        <pattern
          height="1"
          id={`pattern${props?.id}`}
          width="1"
          patternContentUnits="objectBoundingBox"
        >
          <image
            xlinkHref={props.image}
            height="144"
            width="256"
            transform="matrix(0.00434588 0 0 0.00694444 -0.0562721 0)"
            preserveAspectRatio="xMidYMid slice"
          />
        </pattern>
      </defs>
    </svg>
  );
};

export default ImageSqueezeCont3;
