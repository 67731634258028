import React from "react";
import ReactDOM from "react-dom";
import "./SideNav.css";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { toast } from "react-toastify";
import { useState } from "react";
import Loader from "../Loader/Loader";
import { clearUserInfo } from "../../redux/userSlice";
import Logo from "../../assets/svgs/Logo";
import ProviderButton from "./ProviderButton";
import Button from "../Button/Button";
import { useEffect } from "react";
import { fetchWinHistory, selectWinHistory } from "../../redux/winnerHistorySlice";
import UseMyNavigate from "../../Hooks/Common/UseMyNavigate";

const SideNav = ({ closer, show }) => {
  const [loading, setLoading] = useState(false);

  const navigate = UseMyNavigate();
  const dispatch = useDispatch();

  const { user } = useSelector((data) => data.user || {});

  const navitemClickHandler = (to) => {
    closer();
    if(to=='/'&&!user?.id){
      navigate('/luckydraw');
      return
    }
    navigate(to);
  };

  const userSignOut = () => {
    closer();
    dispatch(clearUserInfo());
    navigate("/signin");
  };

  const signInHandler = () => {
    if (!user?.id) {
      navigate("/signin");
    } else {
      setLoading(true);
      axios
        .post("/logout")
        .then((res) => {
          setLoading(false);
          if (res?.data?.status) {
            toast.success(res?.data?.message || "User logged out successfully");
            userSignOut();
          } else {
            userSignOut();
            toast.error(
              res?.data?.message || "Something went wrong try again..."
            );
          }
        })
        .catch((e) => {
          setLoading(false);
          userSignOut();
          toast.error(
            e?.response?.data?.message || "Something went wrong try again..."
          );
        });
    }
  };

  const registerRedirectHandler = () => {
    navigate("/register");
    closer();
  };

  useEffect(() => {
    dispatch(fetchWinHistory());
  }, []);

  const winHistory = useSelector(selectWinHistory);
  console.log(winHistory);

  return ReactDOM.createPortal(
    <>
      {show && <div className="back_drop" onClick={closer}></div>}

      <div className={`sidenav_root_cont ${show ? " show" : " hide"}`}>
        <Loader loading={loading} />
        <div className="side_nav_root side_nav_padding">
          <div className="flex flex-column h-100 justify-between">
            <div className="content">
              {/* <div className=" side-nav-profile flex justify-between">
              <div className="flex align-center">
                <div className="relative">
                  <div className="profile_img_cont">
                    {user?.user?.profile_image ? (
                      <img src={`${user?.user?.profile_image}`} alt="" />
                    ) : (
                      my_icons.user
                    )}
                  </div>
                </div>

                <div className="flex flex-column">
                  <div className="flex align-center mb_0_25">
                    <p className="profile_name">
                      {user?.user?.full_name || ""}
                    </p>
                   
                  </div>
                  <Link className="profile_link" to="/profile">
                    View Profile
                  </Link>
                </div>
              </div>
              <div
                onClick={() => navitemClickHandler("/profile")}
                className="icon_cont"
              >
                {my_icons.chevronRigh}
              </div>
            </div> */}

              <div className="logo-container">
                <Logo />
              </div>

              <div className="menu_items">
                <div
                  onClick={() => {
                    navitemClickHandler("/");
                  }}
                  className="menu_item "
                >
                  <p className="menu_text">Invited Lucky Draws</p>
                </div>
                <div
                  onClick={() => {
                    navitemClickHandler("/history");
                  }}
                  className="menu_item "
                >
                  <p className="menu_text">History</p>
                </div>
              </div>
              <div className="provider_cont">
                {winHistory?.map((each, i) => (
                  <ProviderButton
                    closer={closer}
                    id={each?.provider?.id}
                    prizes_count={each?.total_prizes_won}
                    provider_logo={each?.provider?.avatar}
                    key={i}
                  />
                ))}
              </div>
            </div>

            {!user?.id ? (
              <div className="bottom flex justify-between align-center">
                <p onClick={signInHandler} className=" pointer">
                  Login
                </p>
                <Button
                  className="flex-1"
                  onClick={registerRedirectHandler}
                  type="small"
                  title="Register"
                />
              </div>
            ) : (
              <Button
                className="flex-1"
                onClick={signInHandler}
                type="small"
                title="Logout"
              />
            )}
          </div>
        </div>
      </div>
    </>,
    document.getElementById("side_nav")
  );
};

export default SideNav;
