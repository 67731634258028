import React from "react";
import "./HistoryPage.scss";

const EXPIRED = "expired";
const SPINNER_TYPE = "spinner";

const HistoryItem = ({
  provider_logo,
  title,
  created_at,
  count,
  is_debit,
  debit_type,
  luckydraw_type,
}) => {
  return (
    <div className="history_item">
      <div className="left_cont">
        <div className="provider_logo">
          <img src={provider_logo} alt="" />
        </div>
        <div className="middle">
          <p className="title">{title}</p>
          <p className="created_at">{created_at}</p>
        </div>
      </div>
      <p
        className={`count_cont ${is_debit && " yellow "} ${
          debit_type == EXPIRED && " red"
        }`}
      >
              <p className={`count `}>
                  {is_debit&&"-"}
                  {count}</p>
        <p className="count_title">
          {debit_type == EXPIRED && "Expired"}
          {debit_type != EXPIRED &&
            luckydraw_type == SPINNER_TYPE &&
            "Spinnned"}
          {debit_type != EXPIRED && luckydraw_type != SPINNER_TYPE && "Scratch"}
        </p>
      </p>
    </div>
  );
};

export default HistoryItem;
