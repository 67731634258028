import { Route } from "react-router-dom";
import HomePage from "../pages/homepage/HomePage";
import React from 'react'
import LuckyDrawDetail from "../pages/LuckyDrawDetail/LuckyDrawDetail";
import WinningsPage from "../pages/WinningsPage/WinningsPage";
import HistoryPage from "../pages/HistoryPage/HistoryPage";

export const PublicRoutes = () => {
  return [
    <Route path="/" element={<HomePage />} />,
    <Route path="/luckydraw/:id/:type" element={<LuckyDrawDetail />} />,
    <Route path="/luckydraw" element={<LuckyDrawDetail />} />,
    <Route path="/winnings/:id" element={<WinningsPage />} />,
    <Route path="/history" element={<HistoryPage />} />,
  ];
};


