import React, { useMemo, useState } from "react";
import { tabsData, winningsData } from "./data";
import Tabs from "./Tabs/Tabs";
import CustomInput from "../../Components/CustomInput/CustomInput";
import "./WinningsPage.scss";
import WinningPrizeCard from "../../Components/WinningPrizeCard/WinningPrizeCard";
import WithdrawPopup from "./WithdrawPopup/WithdrawPopup";
import { useParams } from "react-router-dom";
import CustomImage from "../../Components/CustomImage/CustomImage";
import { FILE_BASE_URL } from "../../config/config";
import axios from "axios";
import { useEffect } from "react";
import { getUrlParam } from "../../utils/utils";
import { toast } from "react-toastify";
import Loader from "../../Components/Loader/Loader";
import { FaCheck } from "react-icons/fa6";

const WinningsPage = () => {
  const [username, setUsername] = useState("");
  const [activeTab, setActiveTab] = useState(tabsData[0]);
  const [claimPrizeDetails, setClaimPrizeDetails] = useState(null);
  const [savedUserName, setSavedUsername] = useState(null);

  const [winnings, setWinnings] = useState(null);
  const [loading, setIsLoading] = useState(false);

  const tabClickHandler = (tab) => {
    setActiveTab(tab);
  };

  const claimClickHandler = (id, data) => {
    setClaimPrizeDetails(data);
  };

  const isWinningsSection = activeTab?.type == "my_winnings";
  const { id } = useParams();

  const unClaimedDta = useMemo(() => {
    let prizes = winnings?.prizes?.filter(
      (each) =>
        each?.status == "claim" ||
        each?.status == "not claimed" ||
        each?.status == "win"
    );
    return prizes;
  }, [winnings]);

  const claimedData = useMemo(() => {
    let prizes = winnings?.prizes?.filter(
      (each) =>
        each?.status != "claim" &&
        each?.status != "not claimed" &&
        each?.status != "win"
    );
    return prizes;
  }, [winnings]);

  const fetchWinningsByProviderid = () => {
    setIsLoading(true);
    let route = `/winhistoryuser/${id}`;
    let random_number = getUrlParam("random_number");
    if (random_number) route = `/winhistory?random_number=${random_number}`;
    axios
      .get(route)
      .then((res) => {
        setIsLoading(false);
        if (res?.data?.data?.prizes) {
          let winnings = res?.data?.data?.prizes?.[0];
          setWinnings(winnings);
          console.log("winnings", winnings);
          let username = winnings?.provider?.user_info?.claim_details?.username;
          setUsername(username);
          // setSavedUsername(username);
        }
      })
      .catch((e) => {
        setIsLoading(false);
        toast.error("Failed to Fetch Winnings Data");
        console.log(e);
      });
  };

  useEffect(() => {
    fetchWinningsByProviderid();
  }, []);

  const updateUserName = () =>{
    setIsLoading(true);
    let route = `/update-username`;
    let random_number = getUrlParam("random_number");
    if (random_number) route = `/update-username-guest`;
    axios
    .post(route,{username:username, adminId: id, random_number: random_number})
    .then((res) => {
      setIsLoading(false);
      console.log(res.data.message)
      toast.success(res.data.message);
    })
    .catch((e) => {
      setIsLoading(false);
      toast.error("Failed to Fetch Winnings Data");
      console.log(e);
    });
  }

  console.log(winnings, "some winnings");
  console.log(savedUserName, unClaimedDta);

  return (
    <div className="page winnings_page">
      {loading && <Loader />}
      {claimPrizeDetails && (
        <WithdrawPopup
          winnings={winnings}
          data={claimPrizeDetails}
          closeHandler={() => setClaimPrizeDetails(null)}
          fetchWinningsByProviderid={fetchWinningsByProviderid}
          savedUserName={username}
        />
      )}
      <div className="provider">
        <CustomImage
          src={winnings?.provider?.avatar || ""}
          alt=""
          className="provider_img"
        />
        <p className="title">{winnings?.provider?.name || ""}</p>
      </div>
      <div className="tabs_cont">
        <Tabs
          setActiveTab={setActiveTab}
          activeTab={activeTab}
          tabsData={tabsData}
          tabClickHandler={tabClickHandler}
        />
      </div>
      <div className="winning_prize_list">
        <div className="winning_prizes">
          {(isWinningsSection ? unClaimedDta : claimedData)?.map((each, i) => (
            <WinningPrizeCard
              data={each}
              index={i}
              id={each?.prize_id}
              key={i}
              image={`${FILE_BASE_URL}${each?.prize_image}`}
              title={each?.prize_name}
              showClaim={isWinningsSection}
              claimClickHandler={claimClickHandler}
              isHidePossibility
              reward_amount={each?.amount || 0}
              isShowStatus={!isWinningsSection}
              status={each?.status || ""}
            />
          ))}
        </div>
      </div>
      {isWinningsSection && (
        <div className="provider_username_cont">
          <p className="title">Update dafabet username</p>
          <p className="description">
            You are not Filled the username of dafabet to claim the winnings ,
            fill and claim the your winning Prizes
          </p>
          <div className="input_cont">
            <CustomInput
              name="username"
              onChange={(e) => setUsername(e.target.value || "")}
              placeholder="Enter username here"
              value={username}
            />
            <button className="check_btn" onClick={updateUserName}>
              <FaCheck />
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default WinningsPage;
