import React from "react";
import { historyData } from "./data";
import HistoryItem from "./HistoryItem";

const HistoryPage = () => {
  return (
    <div className="page history_page">
      <p className="section_title">History</p>
      {historyData?.map((each) => (
        <HistoryItem {...each} />
      ))}
    </div>
  );
};

export default HistoryPage;
