import React from "react";
import "./Button.scss";
import OutlineBg from "./OutlineBg";
import { ClipLoader } from "react-spinners";

// type be small || medium
const Button = ({
  title,
  className,
  onClick,
  disabled,
  type = "medium",
  isOutlined,
  btnType,
  loading,
}) => {
  return (
    <button
      type={btnType}
      disabled={disabled}
      onClick={onClick}
      className={`primary_btn ${isOutlined && " outline "} ${type} ${
        className || ""
      }`}
    >
      {!isOutlined && (
        <img
          src={require(type == "medium"
            ? "./btnPrimaryBg.png"
            : "./btnSmallBg.png")}
          alt=""
        />
      )}
      {isOutlined && <OutlineBg />}
      <div className="flex align-center button_title_loader">
        {loading && <ClipLoader className="loader" color={"#94029e"} size={20} />}
        <span className="title">{title}</span>
      </div>
    </button>
  );
};

export default Button;
