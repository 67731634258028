import React from "react";

const Background = (props) => {
  return (
    <svg
      preserveAspectRatio="none"
      fill="none"
      viewBox="0 0 174 198"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M0 30.8447C0 15.9119 0 8.4455 4.71435 4.00425C9.42871 -0.437008 16.8818 0.00802195 31.7881 0.898082L138.238 7.25422C150.969 8.01441 157.334 8.3945 161.369 12.4577C165.403 16.5208 165.738 22.889 166.408 35.6253L173.242 165.563C174.062 181.17 174.473 188.973 169.724 193.7C164.975 198.427 157.174 197.981 141.571 197.09L28.2885 190.616C14.8895 189.851 8.19008 189.468 4.09504 185.132C0 180.797 0 174.086 0 160.665V30.8447Z"
        fill="url(#paint0_linear_901_2819)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_901_2819"
          gradientUnits="userSpaceOnUse"
          x1="88"
          x2="88"
          y1="-30"
          y2="218"
        >
          <stop stopColor="#3B3BD0" />
          <stop offset="1" stopColor="#262668" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default Background;
