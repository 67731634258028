import React from "react";

const MiddleTint = (props) => {
  return (
    <svg
      height="834"
      width="248"
      fill="none"
      viewBox="0 0 248 834"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g>
        <rect
          height="550"
          width="270"
          fill="#EE2AF2"
          fillOpacity="0.3"
          rx="135"
          x="200"
          y="200"
        />
      </g>
      <defs />
    </svg>
  );
};

export default MiddleTint;
