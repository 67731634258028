
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import axios from 'axios'
import { redirectToCallbackUrl } from '../utils/utils';
import { toast } from 'react-toastify';
import { APP_NAME } from '../config/config';
const initialState = {
    isLoading: false,
    user: null,
    error: null,
    field_errors: null
}

export const signInUser = createAsyncThunk('/login', (payload, { rejectWithValue }) => {
    return axios.post('/login', payload).then(res => {
        if (res?.data?.status) {
            toast.success(res?.data?.message || "User logged in Successfully...")
            return res.data
        }
        return rejectWithValue(res.data)
    }).catch(e => {
        let formatError = {
            message: "Field Errors",
            field_errors: e?.response?.data?.errors
        }
        return rejectWithValue(formatError)
    })
});

export const signupUser = createAsyncThunk('/user-register', (payload, { rejectWithValue }) => {
    return axios.post("/user-register", payload).then(res => res.data).catch(e => {
        let formatError = {
            message: "Field Errors",
            field_errors: e?.response?.data?.errors
        }
        return rejectWithValue(formatError)
    })
});



export const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        setUserInfo: (state, action) => {
            state.user = action.payload;
        },
        clearUserInfo: (state, action) => {
            localStorage.removeItem(APP_NAME)
            state.user = null
            state.isLoading = false
        },
        clearFieldErrors: (state, action) => {
            state.error = null;
            state.field_errors = null;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(signInUser.pending, (state) => {
            state.isLoading = true;
        })
        builder.addCase(signInUser.fulfilled, (state, action) => {
            state.isLoading = false;
            let user = action.payload?.data;
            user.token = action.payload?.token ||""
            localStorage.setItem(APP_NAME, JSON.stringify(user))
            state.user = user
        })
        builder.addCase(signInUser.rejected, (state, action) => {
            state.error = action.payload.message;
            state.field_errors = action.payload.field_errors;
            state.isLoading = false;
        })
        //////////////////////////////////////////
        builder.addCase(signupUser.pending, (state) => {
            state.isLoading = true;
        })
        builder.addCase(signupUser.fulfilled, (state, action) => {
            state.isLoading = false;
            let user = action.payload?.data;
            localStorage.setItem('LukyX3UserDetails', JSON.stringify(user))
            state.user = user;
        })
        builder.addCase(signupUser.rejected, (state, action) => {
            state.error = action.payload.message;
            state.field_errors = action.payload.field_errors;
            state.isLoading = false;
        })
    },
})


export const { setUserInfo, clearUserInfo, clearFieldErrors } = userSlice.actions

export default userSlice.reducer;
