import React from "react";

const GuestIcon = (props) => {
  return (
    <svg
      height="80"
      width="80"
      fill="none"
      viewBox="0 0 80 80"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_232_410)">
        <mask
          height="80"
          id="mask0_232_410"
          style={{ maskType: "luminance" }}
          width="80"
          x="0"
          y="0"
          maskUnits="userSpaceOnUse"
        >
          <path d="M0 0H80V80H0V0Z" fill="white" />
        </mask>
        <g mask="url(#mask0_232_410)">
          <g>
            <path
              d="M5.97237 11.5147C15.0284 10.2467 27.0284 6.46273 36.5124 0.930727C37.5624 0.323588 38.7533 0.00258209 39.9662 -0.000225563C41.1791 -0.00303322 42.3716 0.312455 43.4244 0.914727C52.8964 6.33073 65.4324 10.0747 74.0244 11.4267C77.2964 11.9387 79.9884 14.6667 79.9884 17.9787V19.9827C80.1124 44.1547 78.0684 65.3107 42.1004 79.5947C40.7353 80.1319 39.2174 80.1319 37.8524 79.5947C1.91237 65.3107 -0.115632 44.1587 0.00436758 19.9827L0.000367581 17.9947C-0.00763242 14.6747 2.68837 11.9747 5.97237 11.5147ZM37.1724 21.1707C36.4222 21.9207 36.0006 22.9379 36.0004 23.9987V39.9987C36.0004 41.0596 36.4218 42.077 37.1719 42.8272C37.9221 43.5773 38.9395 43.9987 40.0004 43.9987C41.0612 43.9987 42.0787 43.5773 42.8288 42.8272C43.5789 42.077 44.0004 41.0596 44.0004 39.9987V23.9987C44.0002 23.2077 43.7655 22.4345 43.326 21.7769C42.8864 21.1193 42.2618 20.6067 41.531 20.304C40.8002 20.0013 39.9961 19.9221 39.2202 20.0764C38.4444 20.2307 37.7318 20.6115 37.1724 21.1707ZM37.1724 53.1707C36.7903 53.5397 36.4856 53.9811 36.276 54.4691C36.0663 54.9571 35.956 55.482 35.9514 56.0131C35.9468 56.5442 36.048 57.071 36.2491 57.5625C36.4502 58.0541 36.7472 58.5007 37.1228 58.8763C37.4984 59.2519 37.945 59.5489 38.4366 59.75C38.9281 59.9511 39.4549 60.0523 39.986 60.0477C40.5171 60.0431 41.042 59.9328 41.53 59.7231C42.018 59.5135 42.4594 59.2088 42.8284 58.8267C43.557 58.0723 43.9602 57.0619 43.9511 56.0131C43.942 54.9643 43.5213 53.9611 42.7796 53.2195C42.038 52.4778 41.0348 52.0571 39.986 52.048C38.9372 52.0389 37.9268 52.4421 37.1724 53.1707Z"
              fill="url(#paint0_linear_232_410)"
              fillRule="evenodd"
            />
          </g>
        </g>
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_232_410"
          gradientUnits="userSpaceOnUse"
          x1="39.9964"
          x2="39.9964"
          y1="-0.00127322"
          y2="79.9987"
        >
          <stop stopColor="#EE9AE5" />
          <stop offset="1" stopColor="#5961F9" />
        </linearGradient>
        <clipPath id="clip0_232_410">
          <rect height="80" width="80" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default GuestIcon;
