import React, { useEffect, useState } from "react";
import "./CaptchaPopup.scss";
import Modal from "../../../Components/Modal/Modal";
import axios from "axios";
import Button from "../../../Components/Button/Button";
import CustomInput from "../../../Components/CustomInput/CustomInput";
import { toast } from "react-toastify";
import { getUniqueToken, getUrlParam } from "../../../utils/utils";

const CaptchaPopup = ({
  closeHandler,
  spin,
  setSpin,
  setWinningPrize,
  setShowWinningsModal,
}) => {
  const [values, setValues] = useState({});
  const [captchaCode, setCaptchaCode] = useState("");
  const fetchCaptcha = () => {
    axios
      .get(`/generate-verification-code?context=spin_wheel`)
      .then((res) => {
        setCaptchaCode(res?.data?.captchaImageUrl || "");
      });
  };
  useEffect(() => {
    fetchCaptcha();
  }, []);
  const inputChangeHandler = (e) => {
    const name = e?.target?.name;
    const value = e?.target?.value;
    setValues({ ...values, [name]: value });
  };

  const spinClickHandler = (captcha) => {
    const token = getUniqueToken();
    const  type = getUrlParam("type");
    const isScratch = type === "scratch";

    if (!spin && token) {
      setSpin(true);
      axios
        .post(`/spin/${token}?captchaCode=${captcha}`)
        .then((res) => {
          const data = res?.data?.data;
          setTimeout(() => {
            if (data?.prize) {
              setWinningPrize(data?.prize);
              if (!isScratch) {
                // setShowWinningsModal(true);
              } else {
                setTimeout(() => {
                  setShowWinningsModal(true);
                }, 400);
              }
            } else {
              toast.error(data?.message);
            }
            setSpin(false);
          }, 2000);
        })
        .catch((e) => {
          setSpin(false);
          toast.error(
            e?.response?.data?.message || "Spin Error , Try Again later."
          );
        });
    }
  };

  const proceedClickHandler = () => {
    let captcha = values?.captcha;
    if (!captcha) {
      toast.error("Please fill Captcha");
      return;
    }
    spinClickHandler(captcha);
    closeHandler();
  };

  return (
    <Modal show>
      <div className="winning_popup captcha_popup">
        <div className="middle">
          <p className="title">Please Fill Captcha</p>
          <p className="description">
            Fill captcha to verify that you are not a robot
          </p>
        </div>
        <div className="captha_cont">
          <img src={captchaCode} alt="" />
        </div>
        <CustomInput
          name="captcha"
          placeholder="Enter Captcha"
          values={values}
          onChange={inputChangeHandler}
        />
        <div className="bottom">
          <p onClick={closeHandler} className="close_btn pointer">
            Close
          </p>
          <Button onClick={proceedClickHandler} type="small" title="Proceed" />
        </div>
      </div>
    </Modal>
  );
};

export default CaptchaPopup;
