import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { setUserInfo } from "../redux/userSlice";
import { Routes } from "react-router-dom";
import { AuthRoutes } from "./AuthRoutes";
import { PublicRoutes } from "./PublicRoutes";
import { UserRoute } from "./UserRoute";
import NavBar from "../Components/NavBar/NavBar";
import UserRefreshLogin from "../Hooks/Common/UserRefreshLogin";
const AllRoutes = () => {
 const isLoggedin = UserRefreshLogin()
 console.log(isLoggedin,'is logged i')
  return (
    <>
      <NavBar />
      {isLoggedin ? (
        <Routes>
          {PublicRoutes()}
          {UserRoute()}
        </Routes>
      ) : (
        <Routes>
          {PublicRoutes()}
          {AuthRoutes()}
        </Routes>
      )}
    </>
  );
};

export default AllRoutes;
