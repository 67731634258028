import React from "react";
import "./GoBackButton.css";
import { FiChevronLeft } from "react-icons/fi";
import UseMyNavigate from "../../Hooks/Common/UseMyNavigate";
const GoBackButton = ({ isHome }) => {
  const navigate = UseMyNavigate();
  return (
    <div
      onClick={() => {
        isHome ? navigate("/") : navigate(-1);
      }}
      className="goBackButton"
    >
      <FiChevronLeft />
    </div>
  );
};

export default GoBackButton;
